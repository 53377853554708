/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&family=Source+Sans+Pro:wght@300;400;700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Roboto+Slab:wght@300;400;700&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  overflow-x: hidden !important;
  max-height: 100%;
  height: -webkit-fill-available;
  font-family: var(--title-font-style);
}

body {
  margin: 0;
  font-family: var(--title-font-style);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  min-height: 100vh;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

q {
  font-size: clamp(1rem, 2vw, 1.5rem);
}

p {
  font-size: var(--paragraph-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font-style);
}

h1 {
  font-size: var(--main-title-font-size);
  text-transform: uppercase;
  color: var(--headline-text-color);
}

:root {
  /* colors */
  --primary-bg: #fff6f2;
  --header-color: rgb(255, 0, 0);
  --secondary-bg: #ba8475;
  --light-grey: #f2f2f2;
  --banner-color: rgb(0, 167, 225);
  --text-color: #000;
  --headline-text-color: #281713;
  /* font sizes */
  --main-title-font-size: clamp(2.7rem, 6.5vw, 10vw);
  --title-font-size: clamp(2rem, 1.5vw, 6rem);
  --subtitle-font-size: clamp(1.2rem, 1vw, 3rem);
  --paragraph-font-size: clamp(1rem, 1.1rem + 0.05vw, 2.2vw);
  --button-font-size: clamp(1rem, 1.2vw, 4rem);
  --small-text-font-size: clamp(1rem, 0.8vw, 4rem);
  /* font family */
  --title-font-style: 'Raleway', sans-serif;
  /* --text-font-style: 'Roboto Slab', sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
q,
li {
  color: var(--headline-text-color);
}

#nav {
  position: fixed;
  width: 100%;
  top: 0;
  height: 130px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 1000;
  background-color: var(--primary-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1024px) {
  main {
    width: 100%;
    padding-top: 80px;
  }

  #nav {
    height: 80px;
  }
}

a {
  text-decoration: none;
}

.btn {
  font-family: var(--title-font-style);
}
